<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="所在地区:" prop="">
                <el-select
                  v-model="queryInfo.province"
                  placeholder="请选择省份"
                  @change="citychange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select
                  v-model="queryInfo.city"
                  placeholder="请选择地区"
                  @change="districtchange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="queryInfo.district" placeholder="请选择区域" @change="search">
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in areaList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
                <div class="input-with">
                  <div style="display: flex">
                    <el-button type="primary" @click="addginseng()">新增</el-button>
                  </div>
                  <div>
                    <el-input
                      placeholder="请输入智参中心名称"
                      v-model="queryInfo.queryConditions"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                      >搜索
                      </el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <span style="position: relative; top: -10px">当前条件下共查出{{ total }}家智参中心</span>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <img :src="item.coverImage" alt=""/>
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <h5 style="display: inline-block; margin: 0; font-size: 18px">
                    {{ item.zcName }}
                  </h5>
                </div>
                <div style="padding-top: 10px">
                  <span>{{ item.province }}-{{ item.city }}-{{ item.district }}</span>
                </div>
                <p class="address">
                  <span v-for="(o, index) in item.partnerList" :key="index">
                    <span v-if="index < 3" class="office">{{ o }}</span>
                  </span>
                  <el-popover placement="right" width="230" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        flex-wrap: wrap;
                      "
                >
                      <span
                        class="office"
                        style="
                          padding: 1px 6px;
                          display: block;
                          font-size: 14px;
                          font-weight: 400;
                          border-radius: 4px;
                          margin-right: 10px;
                          margin-bottom: 10px;
                        "
                        v-for="(o, indexs) in item.partnerList"
                        :key="indexs"
                      >{{ o }}</span
                      >
                </div>
                <span
                  class="office"
                  slot="reference"
                  v-if="item.partnerList && item.partnerList.length > 3"
                >{{ item.partnerList.length - 1 }}+</span
                >
                </el-popover>
                </p>
              </div>
            </div>
            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <el-button type="primary" @click="toPage(item)">关联企业</el-button>

                <span class="edit" @click="editparter(item)">编辑</span>
                <!-- <span class="frozen" @click="delcompany(item)">冻结</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {wisdomGinsengPage, selectPartnerById} from '@/api/channel'
import {getByFatherIdt, getProvince} from '@/api/demand'

const defaultQueryInfo = Object.freeze({
  channelType: '',
  city: null,
  orderByParam: null,
  pageNum: 1,
  pageSize: 10,
  province: null,
  queryConditions: null,
  district: null
})
export default {
  name: 'ginseng',

  data() {
    return {
      ids: [],
      idsList: [],
      queryInfo: {...defaultQueryInfo},
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [], //省
      operatingLists: [], //市
      areaList: [] // 区
    }
  },
  created() {
    // this.search()
    this.incity()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //关联企业
    toPage(row) {
      this.$router.push({
        name: 'ginsengcompany',
        query: {
          id: row.id
        }
      })
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({provinceCode: id})
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            // console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          // console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.areaList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
            // console.log(this.areaList, 'this.areaList ')
          } else {
            this.areaList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    async search() {
      this.loading = true
      const res = await wisdomGinsengPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    //编辑
    editparter(item) {
      this.$router.push({
        name: 'addginseng',
        query: {
          id: item.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },

    //新增合作伙伴资料
    addginseng() {
      this.$router.push({
        name: 'addginseng'
      })
    },

    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}

::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
  background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.office {
  border: 1px solid #ff7d18;
  color: #ff7d18;
  background: #fff2e8;
  display: inline-block;
  padding: 0px 5px;
  margin-right: 10px;
}

.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }

  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;

      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }

      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}

.user {
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  }

  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;

    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }

    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }

    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }

    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;

      .storeCenter_item_top_type {
        position: absolute;
        width: 93px;
        height: 34px;
        background: #ff7d18;
        color: #fff;
        text-align: center;
        line-height: 34px;
        border-radius: 10px 10px 10px 0px;
        top: 0px;
        left: 0px;
      }

      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 80px;
          height: 80px;
          border-radius: 2px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;

            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }

            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;

              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;

            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }

            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;

              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .address {
            font-size: 14px;
          }
        }
      }

      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;

        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }

        .operation_area {
          margin-top: 30px;

          .buttom_right {
            height: 36px;
            line-height: 1px;
          }

          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }

          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
